<template>
  <div>
    <Logo class="logo" />
    <div class="box h-screen flex flex-col justify-between">
      <div class="text-right py-2 px-4">
        <NuxtLink to="/profile" class="text-white text-lg opacity-60 hover:opacity-100 transition-opacity">
          <client-only><span class="align-middle" v-if="loggedIn">{{ user.nickname }}</span></client-only>
          <Icon i="user" />
        </NuxtLink>
      </div>
      
      <div class="spacer flex-grow"></div>

      <h1 class="text-center font-megrim font-normal text-8xl text-white tracking-wide uppercase title-shadow">
        ALKOV
      </h1>
      
      <div class="flex gap-4 justify-center text-lg">
        <NuxtLink to="/calendar" class="py-2 px-4 text-white opacity-60 hover:opacity-100 drop-shadow-md transition-opacity">
          <Icon i="calendar" />
          <span class="align-middle">Ajouter le planning à mon calendrier</span>
        </NuxtLink>
      </div>
      
      <div class="box text-center">
        <div class="links px-8 pt-4">
          <a target="_blank" rel="noopener" href="https://www.twitch.tv/alkovlive"><img src="~assets/small_icon_net_s.png" alt="Twitch"></a>
          <a target="_blank" rel="noopener" href="https://www.instagram.com/alkov_ig/"><img src="~assets/small_icon_net_i.png" alt="Instagram"></a>
          <a target="_blank" rel="noopener" href="https://www.youtube.com/channel/UCZhOUqTDNMn7Rat2iQtnj9w"><img src="~assets/small_icon_net_y.png" alt="Youtube"></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import Icon from "~/components/Icon.vue";
import Logo from "~/components/Logo.vue";

const { mapState } = createNamespacedHelpers("auth");
export default {
  components: { Icon, Logo },
  auth: false,
  layout: "fullpage",
  head: {
    titleTemplate: "",
  },
  computed: {
    ...mapState(["user", "loggedIn"]),
  },
};
</script>

<style lang="scss" scoped>
.logo {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.box {
  margin-bottom: 50px;
  animation: 2s appear;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
}

.title-shadow {
  text-shadow: #000 0 0 25px, #000 -5px 0 5px, #000 5px 0 5px, #000 -10px 0 10px,
    #000 10px 0 10px;
}

.links img {
  max-width: 64px;
  display: inline;
}

@keyframes appear {
  100% {
    opacity: 1;
  }
}
</style>
