<template>
  <div ref="clouds" class="logo" />
</template>

<script>
import { Clock, WebGLRenderer, Scene, PerspectiveCamera, TextureLoader, PlaneGeometry, MeshBasicMaterial, Mesh, AdditiveBlending } from 'three'
import debounce from 'lodash/debounce'

export default {
  data: () => ({
    debouncedUpdateViewSize: null
  }),
  beforeDestroy () {
    if (this.debouncedUpdateViewSize) {
      window.removeEventListener('resize', this.debouncedUpdateViewSize)
    }
  },
  mounted () {
    const width = this.$refs.clouds.offsetWidth
    const height = this.$refs.clouds.offsetHeight
    const minOpacity = 0.6
    const maxRotationSpeed = 0.3
    const cloudMinSpreadRadius = 75
    const cloudMaxSpreadRadius = 450
    const cloudMinDepth = 150
    const cloudMaxDepth = 700
    const cloudParticleCount = 100
    const cloudSpreadFunction = (_) => {
      const angle = Math.random() * Math.PI * 2
      const radius = Math.sqrt(Math.random()) * (cloudMaxSpreadRadius - cloudMinSpreadRadius) + cloudMinSpreadRadius

      return [radius * Math.cos(angle), radius * Math.sin(angle), (Math.random() * (cloudMaxDepth - cloudMinDepth)) + cloudMinDepth]
    }

    const clock = new Clock()

    const renderer = new WebGLRenderer()
    renderer.setSize(width, height)
    this.$refs.clouds.appendChild(renderer.domElement)

    const scene = new Scene()

    const camera = new PerspectiveCamera(45, width / height, 1, 1000)
    camera.position.set(0, 0, 1000)
    scene.add(camera)

    const textureLoader = new TextureLoader()

    const alkovGeo = new PlaneGeometry(256, 256)
    const alkovTexture = textureLoader.load(require('~/assets/Alkov.jpg'))
    const alkovMaterial = new MeshBasicMaterial({ color: 0xFFFFFF, opacity: 1, map: alkovTexture, transparent: false })
    const alkov = new Mesh(alkovGeo, alkovMaterial)
    alkov.position.y = -20
    scene.add(alkov)

    const smokeTextures = [
      textureLoader.load(require('~/assets/cloud-0.png')),
      textureLoader.load(require('~/assets/cloud-1.png'))
    ]
    const smokeGeo = new PlaneGeometry(350, 350)

    const smokeDefaultMaterial = {
      transparent: true,
      blending: AdditiveBlending
    }
    const smokeColors = [
      0x8500CC,
      0x0013A3,
      0xCC0000,
      0x9E2EA0,
      0x515190,
      0x825B9A
    ]

    const smokeParticles = []
    for (let p = 0; p < cloudParticleCount; p++) {
      const smokeColor = smokeColors[Math.floor(Math.random() * smokeColors.length)]
      const smokeTexture = smokeTextures[Math.floor(Math.random() * smokeTextures.length)]
      const smokeMaterial = new MeshBasicMaterial({ ...smokeDefaultMaterial, color: smokeColor, map: smokeTexture })
      const particle = new Mesh(smokeGeo, smokeMaterial)
      const pos = cloudSpreadFunction()
      particle.position.set(pos[0], pos[1], pos[2])
      particle.rotation.z = Math.random() * 360

      scene.add(particle)
      smokeParticles.push({
        particle,
        maxOpacity: Math.random() * 0.8,
        opacityOffset: Math.random() * 150,
        rotationSpeed: (Math.random() * maxRotationSpeed) - (maxRotationSpeed / 2)
      })
    }

    const updateViewSize = () => {
      const width = this.$refs.clouds.offsetWidth
      const height = this.$refs.clouds.offsetHeight

      camera.aspect = width / height
      camera.updateProjectionMatrix()

      camera.updateProjectionMatrix()
      renderer.setSize(width, height)
    }

    updateViewSize()
    this.debouncedUpdateViewSize = debounce(updateViewSize, 150)
    window.addEventListener('resize', this.debouncedUpdateViewSize)

    const evolveSmokeFn = (delta, elapsedTime) => {
      for (const obj of smokeParticles) {
        obj.particle.rotation.z += (delta * obj.rotationSpeed)
        obj.particle.material.opacity = minOpacity + Math.pow(Math.sin((obj.opacityOffset + elapsedTime)), 2) * obj.maxOpacity
      }
    }

    function animate () {
      const delta = clock.getDelta()
      const elapsedTime = clock.getElapsedTime()

      requestAnimationFrame(animate)
      evolveSmokeFn(delta, elapsedTime)

      renderer.render(scene, camera)
    }

    animate()
  }
}
</script>

<style scoped>
.logo {
  animation: 1s appear;
  @apply w-screen	h-screen bg-black;
}
.logo canvas {
  margin: 0 auto;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
}
</style>
